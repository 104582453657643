import React from 'react';

import { Container, makeStyles, Grid } from '@material-ui/core';

import { Intro } from './Intro';
import { IntroCard } from './IntroCard';
import { CenterFeatures } from './CenterFeatures';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';

const useStyles = makeStyles((theme) => ({
  intro: {
    marginTop: '-5rem',
  },
  centerFeatures: {
		background: '#f5f9ff',
		padding: '18rem 0 20rem',
		[theme.breakpoints.down('sm')]: {
			padding: '10rem 0',
		},
	},
}));

export const WhyBody = ({
	intro,
	introCards,
	bg,
	leftContent,
	ctaText,
	centerSectionCards,
}) => {
	const classes = useStyles();
	return (
		<>
			<Container className={classes.intro}>
				<Intro intro={intro} />
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					style={{ paddingTop: '2rem' }}
					spacing={4}>
					{introCards.map((card, index) => (
						<IntroCard card={card} key={index} />
					))}
				</Grid>
			</Container>
			<WaveDownSVG fill='#FFF' shadow />
			<div
				className={classes.centerFeatures}
				style={{
					backgroundImage: `url(${bg.asset.gatsbyImageData.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<CenterFeatures
					cards={centerSectionCards}
					leftContent={leftContent}
					ctaText={ctaText}
				/>
			</div>
			<WaveUpSVG fill='#FFF' shadow />
		</>
	);
};
